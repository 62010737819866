import Link from 'next/link'
import React, { AnchorHTMLAttributes } from 'react'

interface LinkWithTransitionProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  title?: string
  href: string
}

/**
 *
 * @param props
 * @requires href  link url of the anchor
 * @returns anchor element
 * @description applying animation transition between pages
 */

const LinkWithTransition: React.FC<LinkWithTransitionProps> = props => {
  const { title, children, ...rest } = props
  if (title) {
    return <Link {...rest}>{title}</Link>
  }

  return <Link {...rest}>{children}</Link>
}

export default LinkWithTransition
