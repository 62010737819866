import { cn } from '@/@core/utils/cn'
import { globalBlurDataUrl } from '@/configs/blur'
import Image from 'next/image'
import React from 'react'

interface ResponsiveImageProps extends React.HtmlHTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
  sizes?: string
  imgStyles?: React.CSSProperties
  imgClassName?: string
  figCaption?: string
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = React.forwardRef<HTMLImageElement, ResponsiveImageProps>(
  (
    { sizes = '100vw', imgStyles = {}, imgClassName = '', className = '', style, src, alt, figCaption, ...rest },
    ref: React.ForwardedRef<HTMLImageElement>
  ) => {
    return (
      <figure
        className={cn('relative mx-auto flex justify-center items-center w-full max-w-full h-auto', className)}
        style={style}
        ref={ref} // Forward the ref to the wrapper element
      >
        <Image
          priority
          width={0}
          height={0}
          sizes={sizes}
          className={cn('object-contain block w-full h-auto max-h-full', imgClassName)}
          style={{ ...imgStyles }}
          {...rest}
          // placeholder='blur'
          decoding='async'
          blurDataURL={globalBlurDataUrl}
          title={alt}
          alt={alt}
          src={src}
          data-src={src}
        />
        {figCaption && <figcaption className='text-center mt-2 text-sm text-gray-500'>{figCaption}</figcaption>}
      </figure>
    )
  }
)

ResponsiveImage.displayName = 'ResponsiveImage'
export default ResponsiveImage
