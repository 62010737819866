import { cn } from '@/@core/utils/cn'
// import { useBreakpoint } from '@/v1/hooks/use-break-point'
import React from 'react'

interface PageWrapperProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

//  if we need it to be consist with nav link not header.
//  <div className={cn('mx-auto max-w-[1200px] w-full px-4 py-3 md:py-6', className)} {...rest}>

export default function sectionWrapper({ children, className, ...rest }: PageWrapperProps) {
  // const { isAboveMd } = useBreakpoint('md')

  return (
    <div className={cn('container mx-auto', className)} {...rest}>
      {children}
    </div>
  )
}
